import React, { Component } from "react";
import "./App.css";

import logo_up from "./images/logo_up.png";
import logo_eu from "./images/logo_eu.jpg";
import logo_2 from "./images/logo_2.png";
import brandenburg_app from "./images/brandenburg_app.png";

import leanachan_app from "./images/leanachan_app.png";
import ph_animated from "./images/ph_animated.gif";

import custamization_animated from "./images/customization.gif";
import { Row, Col } from "react-bootstrap";
import DownloadModal from "./downloadModal.js";
import ImpressumModal from "./impressumModal.js";
import "./one-page-wonder.css";

class App extends Component {
  render() {
    return (
      <Row>
        <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={12}>
          <div className="App">
            <header className="header-image">
              <div className="headline">
                <h1>LANDPREF</h1>
                <h2>
                  An Interactive Tool to Assess Desired Land Use Visions Amongst
                  the Public
                </h2>
              </div>
            </header>

            <div className="featurette featurette-first">
              <img
                className="featurette-image  img-responsive pull-right tradeoff_animation"
                src={ph_animated}
                alt="Interactive Simulation"
              />
              <h2 className="featurette-heading">
                Interactive Land Use Trade-off Simulation
              </h2>
              <div className="lead">
                <ul>
                  <li>
                    Enables to adjust virtually preferred land uses for a given
                    landscape
                  </li>
                  <li>
                    Enforces trade-offs between competing land uses and provides
                    visual output of respondents´ choices.
                  </li>
                  <li>
                    Initially developed for the Pentland Hills Regional Park,
                    Scotland, and adapted for Scottish Highlands and Rural
                    Brandenburg in Germany.{" "}
                  </li>
                  <li>
                    Used in online surveys, within face-to-face interviews and
                    during public events.
                  </li>
                  <li>
                    More details:
                    <a href="https://oppla.eu/product/2099">OPPLA.eu</a>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="featurette" id="services">
              <img
                className="featurette-image img-responsive pull-left"
                src={brandenburg_app}
                alt="Brandenburg App"
              />
              <h2 className="featurette-heading">
                Download Landref Applications
              </h2>
              <div className="lead lead_right">
                <ul>
                  <li>
                    <DownloadModal
                      appName="Pentland Hills LANDPREF App"
                      year="2014"
                      file="ph_survey.apk"
                    />{" "}
                  </li>

                  <li>
                    <DownloadModal
                      appName="Scottish Highlands LANDPREF App"
                      year="2016"
                      file="leanachan.apk"
                    />
                  </li>
                  <li>
                    <DownloadModal
                      appName="Brandenburg LANDPREF App"
                      year="2015"
                      file="brandenburg.apk"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="featurette" id="contact">
              <img
                className="featurette-image img-responsive pull-right"
                src={leanachan_app}
                alt="Leanachan App"
              />
              <h2 className="featurette-heading">Get in Contact</h2>

              <div className="lead">
                {" "}
                <ul>
                  <li>
                    <a href="mailto:schmikat@uni-potsdam.de">Katja Schmidt</a>{" "}
                    (Pentland Hills Study){" "}
                  </li>
                  <li>
                    <a href="awalz@uni-potsdam.de">Ariane Walz</a> (Pentland
                    Hills Study)
                  </li>
                  <li>
                    <a href="louise.sing@ed.ac.uk">Louise Sing</a> (Scottish
                    Highlands Study)
                  </li>
                  <li>
                    <a href="mailto:phApp@ruhrlaender.com">Paulo Ruhrländer</a>{" "}
                    (App Development)
                  </li>
                </ul>
              </div>
            </div>
            <hr className="featurette-divider" />

            <div className="featurette" id="services">
              <img
                className="featurette-image img-responsive pull-left customization_animation"
                src={custamization_animated}
                alt="Customisation Examples"
              />
              <h2 className="featurette-heading">Highly Customisable</h2>
              <div className="lead lead_right">
                Individual functionality can be added on demand:
                <ul>
                  <li>Surveys / Questionnaires</li>
                  <li>Maps / GIS Functionality</li>
                </ul>
              </div>
            </div>
            <hr className="featurette-divider" />

            <div className="featurette references" id="References">
              <h2>References</h2>

              <div className="lead lead_right">
                <i>
                  Schmidt, K., Walz, A., Jones, I., & Metzger, M. J. - 2016.
                </i>{" "}
                <br />
                <b>
                  The Sociocultural Value of Upland Regions in the Vicinity of
                  Cities in Comparison With Urban Green Spaces.
                </b>{" "}
                Mountain Research and Development, 36(4), 465-474.{" "}
                <a href="http://www.bioone.org/doi/10.1659/MRD-JOURNAL-D-16-00044.1">
                  Download
                </a>
              </div>
              <div className="lead lead_right">
                <i>
                  Schmidt, K., Walz, A., Martín-López, B., & Sachse, R. - 2017.
                </i>{" "}
                <br />
                <b>
                  Testing socio-cultural valuation methods of ecosystem services
                  to explain land use preferences.
                </b>{" "}
                Ecosystem services, 26, 270-288.{" "}
                <a href="https://www.sciencedirect.com/science/article/pii/S2212041617300578">
                  Download
                </a>
              </div>
            </div>

            <hr className="featurette-divider" />

            <footer>
              <Row>
                <div>
                  The development of LANDPREF was supported by the 7th Framework
                  Programme of the European Commission in the project OPERAs
                  (grant number 308393,{" "}
                  <a href="http://www.operas-project.eu">
                    www.operas-project.eu
                  </a>
                  ).
                </div>
              </Row>
              <hr className="featurette-divider" />

              <div className={"mx-auto"}>
                <img
                  src={logo_up}
                  alt="Logo University of Potsdam"
                  className="logo"
                />
                <img src={logo_2} alt="Logo" className="logo" />
                <img src={logo_eu} alt="Logo" className="logo" />
              </div>
            </footer>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Copyright &copy; 2017 - Institut für Erd- und
                  Umweltwissenschaften - Universität Potsdam{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ImpressumModal />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default App;
