import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class DownloadModal extends Component {
  constructor() {
    super();
    this.state = { showModal: false };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render(props) {
    return (
      <div>
        <Button className={'linkbutton'} variant="primary" onClick={this.open}>
          {this.props.appName}{' '}
        </Button>

        <Modal
          show={this.state.showModal}
          onHide={this.close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Download Landpref Apps</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Here you can download binary versions of our apps. If you just
              want to try out the tradeoff analysis, the easiest way is to use
              our test online version:{' '}
              <a href="https://9qknz91lv0.execute-api.eu-central-1.amazonaws.com/production/survey/4">
                Online Example of tradeoff analysis.
              </a>
            </p>
            <p>
              The following App was developed for the purpose of one of our
              studies. This app can not be used in other contexts and is only
              downloadable to get a sense of the functionality. If you want to
              adopt the Landpref concept to your own study, please contact us.
              We can either help you developing an app for your purpose or make
              the source code available to you.
            </p>
            Please note:
            <ul>
              <li>This app only works on Android devices.</li>
              <li>
                This app was developed for Tablets with a fixed screen size, it
                might not display properly on your device
              </li>
              <li>
                This app was developed in {this.props.year}, and we cannot
                supply bugfixes or ongoing support, so there might be errors or
                security bugs in the app.
              </li>
            </ul>
            <a href="http://eepurl.com/cLkUwf">
              Subscribe to our email list to download applications
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DownloadModal;
